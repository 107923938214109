<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getQueryString } from '@/utils/index';

export default {
  name: 'app',
  data() {
    return {
      // isPc: true,
    };
  },
  created() {
    const tmpInviteCode = getQueryString('inviteCode');
    const bindId = getQueryString('bindId');
    const h5OpenId = getQueryString('h5_openId');
    if (tmpInviteCode) {
      this.$store.commit('setInviteCode', tmpInviteCode);
    }
    if (bindId) {
      this.$store.commit('setBindId', bindId);
      console.log(bindId);
    }
    if (h5OpenId) {
      this.$store.commit('setH5OpenId', h5OpenId);
      console.log(h5OpenId);
    }
    // 如果自定义后台链接访问过
    // 则清除自定义后台链接访问的缓存配置
    const funcs = localStorage.getItem('funcs');
    if (funcs) {
      localStorage.removeItem('funcs');
      localStorage.removeItem('token');
    }

    // this.isPc = isPc();
    // if (!this.isPc) {
    //   console.log(location.href);
    //   if (location.href.indexOf('phoneRegister') > -1) {
    //     this.$router.replace({
    //       path: '/phoneRegister',
    //     });
    //   } else if (location.href.indexOf('home') > -1) {
    //     this.$router.replace({
    //       path: '/phoneHome',
    //     });
    //   } else if (location.href.indexOf('pcHome') > -1) {
    //     this.$router.replace({
    //       path: '/',
    //     });
    //   } else {
    //     this.$router.replace({
    //       path: '/phoneAnnualMeeting',
    //     });
    //   }
    // } else {
    //   this.$router.replace({
    //     path: '/',
    //   });
    // }
    /* eslint-disable */
    // const _53code = document.createElement("script");
    // _53code.src = "https://tb.53kf.com/code/code/2c891055109c51301f8b2e9df6910cdf1/1";
    // const s = document.getElementsByTagName("script")[0];
    // s.parentNode.insertBefore(_53code, s);
  },
  mounted() {
    // window.testShowKefu = this.showKefuScript;
    // setTimeout(() => {
    //   /* eslint-disable */
    //   const _53code = document.createElement("script");
    //   _53code.src = "https://tb.53kf.com/code/code/2c891055109c51301f8b2e9df6910cdf1/1";
    //   const s = document.getElementsByTagName("script")[0];
    //   s.parentNode.insertBefore(_53code, s);
    // }, 10000);
  },
  methods: {},
  watch: {
    // $route({ meta }) {
    //   // this.$nextTick(() => document.title = meta.title || '嗨喵悦动 - 嗨喵悦动大屏互动');
    //   setTimeout(() => document.title = meta.title || '嗨喵悦动 - 嗨喵悦动大屏互动')
    // }
  }
};
</script>

<style lang="less">
/* 全局样式 */
* {
  margin: 0;
  padding: 0;
}
html {
  // font-size: 100px;
}
body {
  // background-color: #f4f4f4;
  background-color: rgba(255, 255, 255, 1);
  color: #000000;
  // font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
}
div {
  box-sizing: border-box;
}
img {
  max-width: 100%;

  height: auto;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
// #mobile_icon_div {
//   display: none !important;
// }

</style>
